import { euRecords } from "./euRecords";
import { Country, ContinentalRegion } from "../../i18n/country";
import { IPricingResponse } from "./types";

const EMPTY_STRING = "";
const COUNTRY_SEPARATOR = "|";

const getReportCombination = (pricingResponse: IPricingResponse): string => {
  const recordCheck = pricingResponse?.recordCheck;
  const euRecordsPerCountry = euRecords.getEuRecordsExceptPresentCountry(pricingResponse);
  const usEntry = Country.USA.toUpperCase();
  const euEntry = ContinentalRegion.EUROPE.toUpperCase();
  const localIsUS: boolean = recordCheck?.presentCountry === Country.USA;
  const hasLocalUSReports = recordCheck?.recordsPerCountry?.us > 0;
  const hasExternalUSReports = recordCheck?.externalReports?.us?.displayRecords > 0;

  const euRecordsResult: string = euRecordsPerCountry.length > 0 ? euEntry : EMPTY_STRING;
  const usRecordsResult: string = hasLocalUSReports || hasExternalUSReports ? usEntry : EMPTY_STRING;
  const presentCountryEntry: string = recordCheck?.presentCountry
    ? recordCheck?.presentCountry?.toUpperCase()
    : EMPTY_STRING;

  return localIsUS
    ? glueReportCombination(presentCountryEntry, euRecordsResult)
    : glueReportCombination(presentCountryEntry, euRecordsResult, usRecordsResult);
};

const glueReportCombination = (...reportRegions: string[]): string => {
  return reportRegions.filter((region) => EMPTY_STRING !== region).join(COUNTRY_SEPARATOR);
};

export const reportCombination = {
  getReportCombination,
};
