export enum Stages {
  DEV = "dev",
  QA = "qa",
  STG = "stg",
  PROD = "prod",
}

export enum NodeStages {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}
