import { configure } from "mobx";

import browserStore from "./browser.store";
import sampleReportStore from "./sampleReport.store";
import layoutStore from "./layout.store";
import reportPricingStore from "./reportPricing.store";
import recordCheckStore from "./recordCheck.store";
import checkoutStore from "./checkout.store";
import userStore from "./user.store";
import invoiceStore from "./invoice.store";
import purchaseJustificationStore from "./purchaseJustification.store";

configure({
  enforceActions: "observed",
  useProxies: "never",
  computedRequiresReaction: false,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
  disableErrorBoundaries: true,
});

const stores = {
  browserStore,
  sampleReportStore,
  layoutStore,
  reportPricingStore,
  recordCheckStore,
  checkoutStore,
  userStore,
  invoiceStore,
  purchaseJustificationStore,
};

export default stores;
