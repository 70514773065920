import { Stages } from "./config/stages";
import { browserUtil } from "./browserUtil";
import { getRoutesWithVin } from "./backend-api/api-routes";

export class Log {
  public static info(logEntity: ILogEntity) {
    if (process.env.APP_ENV !== Stages.PROD || !browserUtil.isBrowser) {
      if (process.env.APP_ENV === Stages.DEV) {
        console.log(LogLvl.INFO, logEntity.event.message);
      } else {
        console.log(logEntityToJson(addLogLevel(logEntity, LogLvl.INFO)));
      }
    }
  }

  public static error(logEntity: ILogEntity) {
    if (process.env.APP_ENV !== Stages.PROD || !browserUtil.isBrowser) {
      if (process.env.APP_ENV === Stages.DEV) {
        console.log(LogLvl.ERROR, logEntity.event.message);
      } else {
        console.log(logEntityToJson(addLogLevel(logEntity, LogLvl.ERROR)));
      }
    }
  }
}

export enum LogLvl {
  ERROR = "ERROR",
  INFO = "INFO",
}

export default new Log();

export interface ILogEntity {
  metadata?: {
    requestId?: string;
    vinreg?: {
      vin?: string;
      licensePlate?: {
        licensePlate?: string;
        country?: string;
      };
    };
  };
  event: {
    message?: string;
    res?: any;
    log?: {
      level?: LogLvl;
    };
    http?: {
      method?: string;
      path?: string;
      status?: number;
      durationMs?: number;
    };
  };
}

export function addLogLevel(logEntity: ILogEntity, logLevel: LogLvl): ILogEntity {
  return {
    event: {
      ...logEntity.event,
      log: {
        level: logLevel,
      },
    },
    metadata: { ...logEntity.metadata },
  };
}

export function buildLogEntity(message: string, req: any, res?: any, durationInMs?: number): ILogEntity {
  return {
    event: {
      message: message,
      res: res,
      http: {
        method: req.method,
        path: req.path ? req.path : req.url,
        status: res?.statusCode ? res?.statusCode : res?.status,
        durationMs: durationInMs ? Math.round(durationInMs) : undefined,
      },
    },
    metadata: { vinreg: getVinregFromRequest(req) },
  };
}

export function buildLogEntityWithoutRequest(message: string, res?: any, durationInMs?: number): ILogEntity {
  return {
    event: {
      message: message,
      res: res,
      http: {
        status: res?.statusCode ? res?.statusCode : res?.status,
        durationMs: durationInMs ? Math.round(durationInMs) : undefined,
      },
    },
  };
}

export function formatMessage(...content): string {
  let result: string = "";
  content.forEach((element) => {
    result += " " + JSON.stringify(element).replace(/(^")|("$)/g, "");
  });
  return result.slice(1);
}

export function hideFieldFromLogging(key, value) {
  if (key === "res") {
    return undefined;
  }
  return value;
}

export function logEntityToJson(logEntity: ILogEntity) {
  const message = logEntity.event.message;
  return JSON.stringify(logEntity, hideFieldFromLogging)
    .replaceAll(JSON.stringify(logEntity.event.message), `"${message}"`)
    .replaceAll("licensePlate", "license-plate")
    .replaceAll("durationMs", "duration-ms");
}

export function redirectConsoleOutput() {
  if (process.env.APP_ENV !== Stages.DEV) {
    globalThis.console.info = (message) => {
      Log.info(buildLogEntityWithoutRequest(message));
    };
    globalThis.console.debug = (message) => {
      Log.info(buildLogEntityWithoutRequest(message));
    };
    globalThis.console.trace = (message) => {
      Log.info(buildLogEntityWithoutRequest(message));
    };
    globalThis.console.error = (message) => {
      Log.error(buildLogEntityWithoutRequest(message));
    };
    globalThis.console.warn = (message) => {
      Log.error(buildLogEntityWithoutRequest(message));
    };
  }
}

export function getVinregFromRequest(request) {
  const routesWithVin = getRoutesWithVin();
  let metadata;
  routesWithVin.forEach((route) => {
    if (request.url?.startsWith(route)) {
      const vinreg = request.url.replace(`${route}/`, "");
      if (vinreg.length >= 14) {
        metadata = { vin: vinreg };
      } else {
        metadata = {
          licensePlate: {
            licensePlate: vinreg,
            country: request.params.lang,
          },
        };
      }
    }
  });
  return metadata ? metadata : { vin: request.query?.vin };
}
