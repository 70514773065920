import { css } from "app";
import { Config } from "utils/config/config";
import { LazyImage } from "react-lazy-images";
import { mediaQuery, spacing } from "@carfax/web-ui/core";

interface Props {
  hasBlueIcons?: boolean;
  facebook?: boolean;
  twitter?: boolean;
  instagram?: boolean;
  youtube?: boolean;
  linkedin?: boolean;
}

const styles = {
  socialMediaWrapper: () =>
    css({
      display: "flex",
      marginTop: spacing.baseUnitMobile2,
      [mediaQuery.desktopOrLarger]: {
        marginTop: 0,
      },
      a: {
        marginRight: spacing.baseUnitMobile3,
        "&:last-of-type": {
          marginRight: 0,
        },
      },
    }),
};

const FacebookIcon = ({ imageProps }) => <img alt="facebook icon" title="facebook icon" {...imageProps} />;
const TwitterIcon = ({ imageProps }) => <img alt="twitter icon" title="twitter icon" {...imageProps} />;
const InstagramIcon = ({ imageProps }) => <img alt="instagram icon" title="instagram icon" {...imageProps} />;
const YoutubeIcon = ({ imageProps }) => <img alt="youtube icon" title="youtube icon" {...imageProps} />;
const LinkedinIcon = ({ imageProps }) => <img alt="linkedin icon" title="linkedin icon" {...imageProps} />;

const SocialMediaLinks: React.FC<Props> = ({ facebook, twitter, instagram, linkedin, youtube }) => {
  const lazyImagePlaceholder = ({ ref }) => <div ref={ref} />;
  return (
    <div css={styles.socialMediaWrapper()}>
      {facebook && (
        <a href={Config.CARFAX_FACEBOOK_URL} aria-label="Facebook" target="_blank" rel="noreferrer">
          <LazyImage
            placeholder={lazyImagePlaceholder}
            actual={FacebookIcon}
            src={`/static/social-media/facebook-square-white.svg`}
          />
        </a>
      )}
      {twitter && (
        <a href={Config.CARFAX_TWITTER_URL} aria-label="Twitter" target="_blank" rel="noreferrer">
          <LazyImage
            placeholder={lazyImagePlaceholder}
            actual={TwitterIcon}
            src={`/static/social-media/twitter-square-white.svg`}
          />
        </a>
      )}
      {instagram && (
        <a href={Config.CARFAX_INSTAGRAM_URL} aria-label="Instagram" target="_blank" rel="noreferrer">
          <LazyImage
            placeholder={lazyImagePlaceholder}
            actual={InstagramIcon}
            src={`/static/social-media/instagram-square-white.svg`}
          />
        </a>
      )}
      {youtube && (
        <a href={Config.CARFAX_YOUTUBE_URL} aria-label="Youtube" target="_blank" rel="noreferrer">
          <LazyImage
            placeholder={lazyImagePlaceholder}
            actual={YoutubeIcon}
            src={`/static/social-media/youtube-square-white.svg`}
          />
        </a>
      )}
      {linkedin && (
        <a href={Config.CARFAX_LINKED_IN_URL} aria-label="Linkedin" target="_blank" rel="noreferrer">
          <LazyImage
            placeholder={lazyImagePlaceholder}
            actual={LinkedinIcon}
            src={`/static/social-media/linkedin-square-white.svg`}
          />
        </a>
      )}
    </div>
  );
};

export default SocialMediaLinks;
