import Link, { LinkProps } from "next/link";
import { withTranslation, WithTranslation } from "next-i18next";

import { linkPropsUtil } from "../util/link-props-util";
import { PropsWithChildren } from "react";

type TProps = LinkProps & WithTranslation & { language?: string; title?: string };

const LinkWrapped: React.FC<PropsWithChildren<TProps>> = ({ language, title, children, ...props }) => {
  const correctedProps = linkPropsUtil.correctLinkProps(props, language ? language : props.i18n.language);
  return (
    <Link {...linkPropsUtil.removeWithTranslationProps(correctedProps)} title={title}>
      {children}
    </Link>
  );
};

export default withTranslation()(LinkWrapped);
