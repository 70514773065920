import { EnvironmentVariables } from "utils/config/environment-variables";

const loadTagManager = async () => {
  const GoogleTagManager = (await import("react-gtm-module")).default;
  GoogleTagManager.initialize({
    gtmId: EnvironmentVariables.getGoogleTagManagerIdWeb(),
  });
};

export const googleTagManager = {
  loadTagManager,
};
