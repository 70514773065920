import React from "react";
import { styles } from "./AdditionalLabel.style";

export interface AdditionalLabelProps {
  text: string;
}

export const AdditionalLabel: React.FC<AdditionalLabelProps> = ({ text }) => {
  return <span css={styles.root()}>{text}</span>;
};
