const content = {
  vhrIframe: 874,
  width: 1200,
  tabletWidth: 768,
  tabletLandscapeWidth: 1024,
  mobileWidth: 580,
  smallMobileWidth: 375,
  qhd: 2560,
  padding: 30,
  paddingMobile: 10,
  paperWrapper: {
    medium: {
      widthMobile: 480,
      widthDesktop: 620,
    },
  },
};

export { content };
