import { NodeStages, Stages } from "./stages";
import { EnvironmentVariables } from "./environment-variables";

const isApplicationRunningInNodeModeProduction = () => NodeStages.DEVELOPMENT !== EnvironmentVariables.getNodeEnv();

const isApplicationRunningInNodeModeDevelopment = () => NodeStages.DEVELOPMENT === EnvironmentVariables.getNodeEnv();

const isRunningOnStg = () => Stages.STG === EnvironmentVariables.getAppEnv();

export const environment = {
  isApplicationRunningInNodeModeProduction,
  isApplicationRunningInNodeModeDevelopment,
  isRunningOnStg,
};
