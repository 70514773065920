import { content } from "./content.style";

const mediaQuery = {
  // mobile first driven, default CSS scope is mobile
  largerThanMobile: `@media screen and (min-width: ${content.mobileWidth}px)`,
  tabletOrLarger: `@media screen and (min-width: ${content.tabletWidth}px)`,
  tabletLandscapeOrLarger: `@media screen and (min-width: ${content.tabletLandscapeWidth}px)`,
  desktopOrLarger: `@media screen and (min-width: ${content.width}px)`,
  smallerThanTablet: `@media screen and (max-width: ${content.tabletWidth - 1}px)`,
  smallerThanMobile: `@media screen and (max-width: ${content.smallMobileWidth}px)`,
  noneDesktop: `@media screen and (max-width: ${content.width}px)`,
  qhdOrLarger: `@media screen and (min-width: ${content.qhd}px)`,
};

export { mediaQuery };
