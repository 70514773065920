import { IColors } from "./color.types";

const colors: IColors = {
  black: {
    hex: "#000000",
    rgba: "0,0,0",
  },
  white: {
    hex: "#FFFFFF",
    rgba: "255,255,255",
  },
  darkGrey: {
    hex: "#333333",
    rgba: "51,51,51",
  },
  warmGrey: {
    hex: "#384044",
    rgba: "58,64,68",
  },
  headlineGrey: {
    hex: "#4D4D4D",
    rgba: "77,77,77",
  },
  stdTextGrey: {
    hex: "#808080",
    rgba: "128,128,128",
  },
  lightGrey: {
    hex: "#B1B4B4",
    rgba: "177,180,180",
  },
  disabledGrey: {
    hex: "#E6E6E6",
    rgba: "230,230,230",
  },
  backgroundGrey: {
    hex: "#F2F2F2",
    rgba: "242,242,242",
  },
  splitterGrey: {
    hex: "#FBFBFB",
    rgba: "251, 251, 251",
  },
  blue: {
    hex: "#00427B",
    rgba: "0,66,123",
  },
  linkBlue: {
    hex: "#0B64A0",
    rgba: "11,100,160",
  },
  paleBlue: {
    hex: "#5995BA",
    rgba: "89,149,186",
  },
  babyBlue: {
    hex: "#7ECEF4",
    rgba: "126,206,244",
  },
  green: {
    hex: "#76B830",
    rgba: "118,184,48",
  },
  lightGreen: {
    hex: "#EEF4E7",
    rgba: "238,244,231",
  },
  red: {
    hex: "#E10000",
    rgba: "225,0,0",
  },
  strongOrange: {
    hex: "#E46D0C",
    rgba: "228,109,12",
  },
  yellow: {
    hex: "#F2B608",
    rgba: "242,182,8",
  },

  borderGrey: {
    hex: "#666666",
    rgba: "102,102,102",
  },
  textGreyDark: {
    hex: "#7F7F7F",
    rgba: "127,127,127",
  },
  outlineGrey: {
    hex: "#B3B3B3",
    rgba: "179,179,179",
  },
  textGrey: {
    hex: "#CCCCCC",
    rgba: "204,204,204",
  },
  darkBlue: {
    hex: "#11437A",
    rgba: "17,67,122",
  },
  inputBorder: {
    hex: "#0964A1",
    rgba: "9,100,161",
  },
  backgroundBlue: {
    hex: "#80A3BD",
    rgba: "128,163,189",
  },
  paleBlueLight: {
    hex: "#DEEBF2",
    rgba: "222,234,241",
  },
  greenAlert: {
    hex: "#E1EDD1",
    rgba: "225,237,209",
  },
  redAlert: {
    hex: "#FCE3E5",
    rgba: "252,227,229",
  },
  orange: {
    hex: "#F18416",
    rgba: "241,132,22",
  },
  transparent: {
    hex: "transparent",
    rgba: "255,255,255,0",
  },
  darkTransparent: {
    hex: "#00000055",
    rgba: "0,0,0,85",
  },
  halloweenPurple: {
    hex: "#280339",
    rgba: "40,3,57,1",
  },
  paypalYellow: {
    hex: "#FCBB32",
    rgba: "252, 187, 50, 1",
  },
  paypalBlue: {
    hex: "#003087",
    rgba: "0, 48, 135, 1",
  },
};

export { colors };
