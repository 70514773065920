import { DefaultSampleReport } from "contentful/PageModularModule";
import { action, makeObservable, observable } from "mobx";

class SampleReportStore {
  @observable
  public isSampleReportMobileOpen: boolean;

  @observable
  public defaultSampleReport: DefaultSampleReport;

  constructor() {
    makeObservable(this);
    this.isSampleReportMobileOpen = false;
  }

  @action
  public toggleSampleReportMobileOpen() {
    this.isSampleReportMobileOpen = !this.isSampleReportMobileOpen;
  }

  @action
  setSampleReport(defaultSampleReport: DefaultSampleReport) {
    this.defaultSampleReport = defaultSampleReport;
  }
}

export default new SampleReportStore();
export { SampleReportStore };
