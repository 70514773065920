import { css } from "@emotion/react";
import { colors, spacing } from "@carfax/web-ui/core";

export const styles = {
  root: () =>
    css({
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: `${spacing.baseUnitMobile1} ${spacing.baseUnitMobile2}`,
      borderBottom: `1px solid ${colors.borderGrey.hex}`,
    }),
  logo: () =>
    css({
      background: colors.white.hex,
      borderRadius: "10%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "48px",
      height: "32px",
      margin: spacing.baseUnitMobile1,
      img: {
        width: "100%",
      },
    }),
  icon: (icon: string) =>
    css({
      content: `url(${icon})`,
      width: "42px",
      height: "40px",
    }),
};
