import { EnvironmentVariables } from "../utils/config/environment-variables";
import { ContentfulClientApi, createClient, Entry, EntryCollection } from "contentful";
import { Log, buildLogEntityWithoutRequest, formatMessage } from "../utils/log";
import memoryCache from "memory-cache";

let instance: ContentfulClientApi;
let contentfulInstance: ContentfulClientApi;

const workingCacheKey = "small-cache";

const getInstance = (): ContentfulClientApi => {
  if (!instance) {
    const space = EnvironmentVariables.getContentfulSpace();
    const accessToken = EnvironmentVariables.getContentfulAccessToken();
    const environment = EnvironmentVariables.getContentfulEnvironment();
    const startTime = performance.now();
    contentfulInstance = createClient({
      space,
      accessToken,
      environment,
      /* @ts-ignore */
      responseLogger: (logs: { status: any; statusText: any }) => {
        const durationInMs = performance.now() - startTime;
        try {
          Log.info(
            buildLogEntityWithoutRequest(
              formatMessage("contentful", logs.status, logs.statusText),
              {
                status: logs.status,
              },
              durationInMs
            )
          );
        } catch (error) {
          Log.error(buildLogEntityWithoutRequest(formatMessage(error), error, durationInMs));
        }
        /* @ts-enable */
      },
    });

    instance = {
      ...contentfulInstance,
      getEntries: async <T>(query?: any): Promise<EntryCollection<T>> => {
        const entriesFromCache = memoryCache.get(workingCacheKey + JSON.stringify(query));
        if (entriesFromCache) {
          return entriesFromCache;
        }
        const entries = await contentfulInstance.getEntries<T>(query);
        if (entries) {
          memoryCache.put(JSON.stringify(query), entries, cacheTimeoutMilliseconds);
          memoryCache.put(workingCacheKey + JSON.stringify(query), entries, smallCacheTimeoutMilliseconds);
          return entries;
        } else {
          Log.info(buildLogEntityWithoutRequest(formatMessage("from memory", memoryCache.get(JSON.stringify(query)))));
          return memoryCache.get(JSON.stringify(query));
        }
      },
      getEntry: async <T>(id: string, query?: any): Promise<Entry<T>> => {
        const entriesFromCache = memoryCache.get(workingCacheKey + id + JSON.stringify(query));
        if (entriesFromCache) {
          return entriesFromCache;
        }
        const entries = await contentfulInstance.getEntry<T>(id, query);
        if (entries) {
          memoryCache.put(id + JSON.stringify(query), entries, cacheTimeoutMilliseconds);
          memoryCache.put(workingCacheKey + id + JSON.stringify(query), entries, smallCacheTimeoutMilliseconds);
          return entries;
        } else {
          Log.info(
            buildLogEntityWithoutRequest(formatMessage("from memory" + memoryCache.get(id + JSON.stringify(query))))
          );
          return memoryCache.get(id + JSON.stringify(query));
        }
      },
    };
  }
  return instance;
};

export const cacheTimeoutMilliseconds = 30 * 60 * 1000;
export const smallCacheTimeoutMilliseconds = 5 * 60 * 1000;

export const contentfulClient = {
  getInstance,
  cacheTimeoutMilliseconds,
};
