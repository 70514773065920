import { css } from "@emotion/react";
import { colors, fontSizes, lineHeights, mediaQuery, spacing } from "@carfax/web-ui/core";

export const styles = {
  footer: () =>
    css({
      width: "100%",
      backgroundColor: colors.darkGrey.hex,
      color: colors.white.hex,
      fontSize: fontSizes.body.cat,
      lineHeight: lineHeights.body.cat,
      fontWeight: "bold",
      position: "relative",
    }),
  footerContainer: () =>
    css({
      borderTop: `1px solid ${colors.white.hex}`,
    }),
  footerContainerContent: () =>
    css({
      margin: "0 auto",
      maxWidth: "1200px",
      padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile2}`,
      [mediaQuery.desktopOrLarger]: {
        padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile3}`,
      },
    }),
  footerBorder: () =>
    css({
      borderTop: `1px solid ${colors.borderGrey.hex}`,
    }),
  footerTextWrapper: () =>
    css({
      display: "flex",
      flexDirection: "column",
      [mediaQuery.desktopOrLarger]: {
        justifyContent: "space-between",
        flexDirection: "row",
      },
    }),
  footerSslWrapper: () =>
    css({
      display: "flex",
      "& > div": {
        width: "24px",
        height: "24px",
        marginRight: spacing.baseUnitMobile2,
      },
    }),
  footerNav: () =>
    css({
      marginBottom: `-${spacing.baseUnitMobile3}`,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: "initial",
      },
    }),
  footerMenuItem: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: 0,
      },
    }),
  footerMenuItemLinkWrapper: () =>
    css({
      "&:hover": {
        textDecoration: "none",
      },
    }),
  footerMenuItemLink: () =>
    css({
      color: colors.white.hex,
      cursor: "pointer",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
      "&:hover": {
        textDecoration: "underline",
      },
      button: {
        color: `${colors.white.hex}!important`,
        cursor: "pointer!important",
        fontSize: "inherit!important",
        lineHeight: "inherit!important",
        fontWeight: "inherit",
        padding: "0!important",
        background: "none!important",
        border: "none!important",
      },
    }),
  footerBottomWrapper: () =>
    css({
      [mediaQuery.desktopOrLarger]: {
        display: "flex",
        justifyContent: "space-between",
      },
      minHeight: "28.5px",
      "& > div": {
        minHeight: "28.5px",
      },
    }),
  text: () =>
    css({
      color: "inherit",
      marginBottom: 0,
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
    }),
  footerLanguageSwitch: () =>
    css({
      marginTop: spacing.baseUnitMobile3,
      [mediaQuery.desktopOrLarger]: {
        marginTop: 0,
      },
    }),
  languageIcon: () =>
    css({
      width: "32px",
      height: "22px",
    }),
  securityIcon: () =>
    css({
      width: "24px",
      height: "24px",
      minWidth: "24px",
      marginRight: spacing.baseUnitMobile2,
    }),
  footerMissionStatement: () =>
    css({
      color: "inherit",
      marginBottom: spacing.baseUnitMobile3,
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
      [mediaQuery.desktopOrLarger]: {
        marginBottom: 0,
        marginRight: spacing.baseUnitMobile3,
      },
      "& > span": {
        color: "inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
      },
    }),
  footerMenuColumn: () =>
    css({
      [mediaQuery.desktopOrLarger]: {
        "&> li ~ li": {
          marginTop: spacing.baseUnitMobile3,
        },
      },
    }),
  column: () =>
    css({
      "&&": {
        padding: 0,
      },
    }),
};
