import { languageConfig } from "../../i18n/language-config";
import { LanguageResolver } from "../../i18n/language-resolver";
import { customRouteMap } from "../../domain/Routing/customRouteMap";

const i18nLink = (route: string) => {
  const language = LanguageResolver.getCurrentLanguageShort();
  return i18nLinkWithLanguage(route, language);
};

const i18nLinkWithLanguage = (route: string, language: string) => {
  const homePageRoute = "/";
  const isFallbackLanguage = language === languageConfig.getFallbackLanguage();
  const languageUrlSubPath = languageConfig.mapLanguageToLanguageUrlSubpath(language);

  if (isFallbackLanguage && isRouteEmptyOrHome(route)) {
    return homePageRoute;
  } else if (isPressRouteWithForbiddenLanguage(route, language)) {
    return customRouteMap.press.defaultRoute;
  } else if (isCareersRouteWithForbiddenLanguage(route, language)) {
    return customRouteMap.careers.defaultRoute;
  } else if (isRouteEmptyOrHome(route)) {
    return `${languageUrlSubPath}`;
  } else if (isLanguageAlreadyInPath(route, language)) {
    return route;
  } else {
    return `${languageUrlSubPath}${route}`;
  }
};

const isRouteEmptyOrHome = (route: string) => "/" === route || "" === route;

const isPressRouteWithForbiddenLanguage = (route: string, language: string) =>
  route === customRouteMap.press.routes[language] && languageConfig.isLanguageForbiddenInPressPath(language);

const isCareersRouteWithForbiddenLanguage = (route: string, language: string) =>
  route === customRouteMap.careers.defaultRoute && !languageConfig.isLanguageAllowedInCareersPath(language);

const isLanguageAlreadyInPath = (route: string, language: string) => route && route.startsWith(`/${language}`);

export const linkUtil = {
  i18nLink,
  i18nLinkWithLanguage,
};
