import { LinkProps } from "next/link";
import { WithTranslation } from "next-i18next";
import { languageConfig } from "../language-config";
import { linkUtil } from "../../utils/i18n/link-util";

const removeWithTranslationProps = (props: any) => {
  const strippedProps = { ...props };

  delete strippedProps.defaultNS;
  delete strippedProps.i18n;
  delete strippedProps.i18nOptions;
  delete strippedProps.lng;
  delete strippedProps.reportNS;
  delete strippedProps.t;
  delete strippedProps.tReady;
  return strippedProps;
};

const addLanguagePrefixToHref = (correctedProps, props, locale) => {
  const language = languageConfig.mapLocaleToLanguageShort(locale);
  /**
   * `next-i18next` removes the subpath from the URL and includes it in the request
   * query. This is useful if you access the `src/pages/page-2.tsx` file via
   * http://localhost/en/page-2 or http://localhost/nl/page-2, for example.
   * However, if you want separate content structures for each region, you want to
   * access the `src/pages/en/page-2.tsx` file via http://localhost/en/page-2, and
   * the `src/pages/nl/page-2.tsx` file via http://localhost/en/page-2.
   *
   * This wrapper restores the subpath in the request URL, which is used by
   * the default Next.js routing as path to the files in ´src/pages/`.
   */
  correctedProps.href = linkUtil.i18nLinkWithLanguage(props?.href?.toString(), language);
};

const correctLinkProps = (props: LinkProps & WithTranslation & { children?: React.ReactNode }, locale: string) => {
  const correctedProps = { ...props };

  if (locale !== languageConfig.getDefaultLocale()) {
    addLanguagePrefixToHref(correctedProps, props, locale);
  }

  return correctedProps;
};

export const linkPropsUtil = {
  removeWithTranslationProps,
  correctLinkProps,
};
