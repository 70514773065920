export enum LoginMethod {
  "APPLE",
  "DEFAULT",
}

export const isSocialLoginMethod = (method: string | LoginMethod) => {
  switch (method) {
    case LoginMethod.APPLE:
    case "APPLE":
      return true;
    case LoginMethod.DEFAULT:
    case "DEFAULT":
      return false;
    default:
      return false;
  }
};
