import { IPricingResponse } from "./types";
import { Country } from "../../i18n/country";

const getEuRecordsExceptPresentCountry = (pricingResponse: IPricingResponse) =>
  Object.entries(pricingResponse?.recordCheck?.recordsPerCountry || {}).filter(
    ([key]) => key !== Country.USA && key !== pricingResponse?.recordCheck?.presentCountry
  );

export const euRecords = {
  getEuRecordsExceptPresentCountry,
};
