export enum Language {
  DUTCH = "nl",
  ENGLISH = "en",
  GERMAN = "de",
  ITALIAN = "it",
  POLISH = "pl",
  SLOVENE = "sl",
  FRENCH = "fr",
  RUSSIAN = "ru",
  SPANISH = "es",
  SWEDISH = "sv",
  DANISH = "da",
  PORTUGUESE = "pt",
}

export enum Country {
  USA = "us",
  GREAT_BRITAIN = "GB",
  NETHERLANDS = "NL",
  ENGLAND = "EN",
  GERMANY = "DE",
  ITALY = "IT",
  POLAND = "PL",
  SLOVENIA = "SI",
  FRANCE = "FR",
  RUSSIA = "RU",
  SPAIN = "ES",
  SWEDEN = "SE",
  DENMARK = "DK",
  PORTUGAL = "PT",
}

export enum Locales {
  DUTCH = "nl-NL",
  USA = "en-US",
  GERMAN = "de-DE",
  ITALIAN = "it-IT",
  POLISH = "pl-PL",
  SLOVENE = "sl-SI",
  FRENCH = "fr-FR",
  RUSSIAN = "ru-RU",
  SPANISH = "es-ES",
  SWEDISH = "sv-SE",
  DANISH = "da-DK",
  PORTUGUESE = "pt-PT",
}

export enum ContinentalRegion {
  EUROPE = "eu",
  NORTH_AMERICA = "us",
}
