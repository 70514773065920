import { css } from "@emotion/react";
import { colors } from "../styles";

import { mediaQuery } from "../styles/mediaquery.style";

export const styles = css({
  "*": {
    boxSizing: "border-box",
  },
  "#__next": {
    [mediaQuery.noneDesktop]: {
      overflowX: "hidden",
    },
  },
  button: {
    cursor: "pointer",
  },
  ":focus:not(:focus-visible)": {
    outline: 0,
  },
  ":focus-visible": {
    outline: `${colors.linkBlue.hex} auto 2px`,
  },
});
