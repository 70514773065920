import { countryCodes } from "domain/PaymentFlow/Payment/Invoice/CountryOptions/countries";
import { IInvoiceForm } from "domain/PaymentFlow/Payment/Invoice/InvoiceFieldset/InvoiceForm/InvoiceForm";
import { IInvoiceAccountData } from "domain/PaymentFlow/Payment/Invoice/utils/InvoiceForm.utils";
import { action, observable, computed, makeObservable } from "mobx";

class InvoiceStore {
  @observable
  public email: string;

  @observable
  public viesCheckInProgress: boolean;

  @observable
  public viesValidation: boolean | null;

  @observable
  public viesError: string | null;

  @observable
  public vatNumber: string;

  @observable
  public companyOrUserName: string;

  @observable
  public street: string;

  @observable
  public zipCode: string;

  @observable
  public city: string;

  @observable
  public country: string;

  @observable
  public isInvoiceRequested: boolean;

  @observable
  public formInEdit: boolean;

  @observable
  public skipFormModal: boolean;

  @observable
  public externalServiceUserId: string;

  @observable
  public isServerDataUpdated: boolean;

  constructor() {
    makeObservable(this);
    this.clear();
  }

  @action
  clear() {
    this.email = "";
    this.viesValidation = null;
    this.vatNumber = "";
    this.isInvoiceRequested = false;
    this.companyOrUserName = "";
    this.street = "";
    this.zipCode = "";
    this.city = "";
    this.country = "";
    this.formInEdit = false;
    this.skipFormModal = false;
    this.externalServiceUserId = "";
    this.viesCheckInProgress = false;
    this.isServerDataUpdated = false;
  }

  @action
  setVatData(vatNumber: string, isValid: boolean) {
    this.vatNumber = vatNumber;
    this.viesValidation = isValid;
  }

  @action
  setViesError(viesError: string) {
    this.viesError = viesError;
  }

  @action
  setUserInvoiceData({
    companyOrUserName,
    street,
    zipCode,
    city,
    country,
    externalServiceUserId,
  }: IInvoiceForm & IInvoiceAccountData) {
    this.companyOrUserName = companyOrUserName;
    this.street = street;
    this.zipCode = zipCode;
    this.city = city;
    this.country = country;
    this.formInEdit = false;
    this.externalServiceUserId = externalServiceUserId;
  }

  @action
  setIsFormInEdit(formInEdit: boolean) {
    this.formInEdit = formInEdit;
  }

  @action
  setSkipFormModal(skipFormModal: boolean) {
    this.skipFormModal = skipFormModal;
  }

  @action
  setIsInvoiceRequested(isInvoiceRequested: boolean) {
    this.isInvoiceRequested = isInvoiceRequested;
  }

  @action
  setViesCheckInProgress(viesCheckInProgress: boolean) {
    this.viesCheckInProgress = viesCheckInProgress;
  }

  @action
  setIsServerDataUpdated(isServerDataUpdated: boolean) {
    this.isServerDataUpdated = isServerDataUpdated;
  }

  @computed
  public get invoiceDataValid() {
    return this.city && this.zipCode && this.street && this.companyOrUserName && !this.formInEdit;
  }

  @computed
  public get invoiceDataExist() {
    return this.city && this.zipCode && this.street && this.companyOrUserName && this.country;
  }

  @computed
  public get invoiceDataConfirmed() {
    return !this.isInvoiceRequested || (this.isInvoiceRequested && this.invoiceDataValid && this.isServerDataUpdated);
  }

  @computed
  public get vatCountryCode() {
    const vatCode = this.vatNumber.replaceAll(" ", "").slice(0, 2).toUpperCase();
    return countryCodes.find((countryCode) => countryCode === vatCode) ? vatCode : "";
  }

  @computed
  public get viesCheckFormValues() {
    return { vatNumber: this.vatNumber };
  }

  @computed
  public get mainFormValues() {
    return {
      companyOrUserName: this.companyOrUserName,
      street: this.street,
      zipCode: this.zipCode,
      city: this.city,
      country: this.country,
      vatNumber: this.vatNumber,
    };
  }

  @computed
  get toObject() {
    return {
      isInvoiceRequested: this.isInvoiceRequested,
      viesValidation: this.viesValidation,
      vatNumber: this.vatNumber,
      viesError: this.viesError,
      address: {
        companyOrUserName: this.companyOrUserName,
        street: this.street,
        zipCode: this.zipCode,
        city: this.city,
        country: this.country,
      },
      viesCheckInProgress: this.viesCheckInProgress,
    };
  }

  @computed
  get toJSON() {
    return JSON.stringify(this.toObject);
  }
}

export default new InvoiceStore();
export { InvoiceStore };
