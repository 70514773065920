import { action, makeObservable, observable } from "mobx";

export enum PurchaseJustifications {
  CAR_OWNER = "carOwner",
  BUYER = "buyer",
}

class PurchaseJustificationStore {
  @observable
  public legitimateInterest: PurchaseJustifications | undefined;

  @observable
  public legitimateInterestError: boolean = false;

  constructor() {
    makeObservable(this);
    this.setReason = this.setReason.bind(this);
    this.clearReason = this.clearReason.bind(this);
    this.checkIfReasonProvided = this.checkIfReasonProvided.bind(this);
  }

  @action
  public setReason(reason: PurchaseJustifications): void {
    this.legitimateInterest = reason;
    this.legitimateInterestError = false;
  }

  @action
  public clearReason(): void {
    this.legitimateInterest = undefined;
    this.legitimateInterestError = false;
  }

  @action
  public checkIfReasonProvided(): boolean {
    if (!this.legitimateInterest) {
      this.legitimateInterestError = true;
      return false;
    }
    return true;
  }
}

export default new PurchaseJustificationStore();
export { PurchaseJustificationStore };
