export const Config = {
  ADYEN_CSS_URL: "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.css",
  ADYEN_JS_URL: "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.3/adyen.js",
  RECAPTCHA_JS_SCRIPT_URL: "https://www.google.com/recaptcha/api.js?render=",
  CARFAX_FACEBOOK_URL: "https://www.facebook.com/Carfax.eu/",
  CARFAX_LINKED_IN_URL: "https://www.linkedin.com/company/carfax-europe",
  CARFAX_TWITTER_URL: "https://twitter.com/CARFAX_EU",
  CARFAX_YOUTUBE_URL: "https://www.youtube.com/channel/UCK9gyqqrddg_7xqwxEgVomA",
  CARFAX_INSTAGRAM_URL: "https://www.instagram.com/carfaxeurope/",
};
