import { useRouteForSlug } from "./useRouteForSlug";
import { customRouteMap, RoutesKeys } from "domain/Routing/customRouteMap";

type Routes = {
  [Key in RoutesKeys]?: string;
};

export const useRoutes = (): Routes => {
  return Object.keys(customRouteMap).reduce((acc, slug) => ({ ...acc, [slug]: useRouteForSlug(slug) }), {});
};
