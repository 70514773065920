import { action, observable, computed, autorun, makeObservable } from "mobx";
import { LocalStorage } from "utils/localstorage";
import { CreditsPackage } from "utils/pricing-response/types";

class ReportPricingStore {
  private key = "reportPricing_store_local";
  private localStorage: LocalStorage;

  @observable
  public totalAmount: number = 0;

  @observable
  public currency: string;

  @observable
  public creditsPackages: CreditsPackage[];

  constructor() {
    makeObservable(this);
    this.localStorage = new LocalStorage(this.key);

    this.loadDataFromLocalStorage();

    autorun(() => {
      this.localStorage.saveDataToLocalStorage(this.toJSON);
    });
  }

  @computed
  get toObject() {
    return {
      totalAmount: this.totalAmount,
      currency: this.currency,
      creditsPackages: this.creditsPackages,
    };
  }

  @computed
  get toJSON() {
    return JSON.stringify(this.toObject);
  }

  @action
  public setDataFromApi(data) {
    const { reportPricing, packagesPricing } = data;
    this.totalAmount = reportPricing.totalAmount;
    this.currency = reportPricing.currency;
    this.creditsPackages = packagesPricing.creditsPackages;
  }

  @action
  private loadDataFromLocalStorage() {
    const data = this.localStorage.loadDataFromLocalStorage<this>();
    if (!data) {
      return null;
    }

    this.totalAmount = data.totalAmount;
    this.currency = data.currency;
    this.creditsPackages = data.creditsPackages;
  }

  @computed
  get trackingData() {
    return {
      currency: this.currency,
      totalAmount: this.totalAmount,
    };
  }
}

export default new ReportPricingStore();
export { ReportPricingStore };
