import { Grid } from "@carfax/web-ui/elements/Grid";
import SocialMediaLinks from "components/Accessories/SocialMediaLinks";
import { styles } from "./Footer.style";
import { useFooterMenuItems } from "./hooks/useFooterMenuItems";
import CustomElement from "./CustomElement";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import { LazyImage } from "react-lazy-images";
import { useTranslation } from "../../../app";
import PaymentLogos from "./PaymentLogos";
import { Icons } from "@carfax/web-ui/elements/Icon";

export interface IFooterItem {
  text: string;
  href?: string;
  testSelector?: string;
  external?: boolean;
  customFooterItem?: boolean;
  slug?: string;
  additionalLabel?: string;
}

export interface IFooterProps {
  hidePaymentLogosInFooter?: boolean;
}
const Image = ({ imageProps }) => <img alt="" css={styles.securityIcon()} {...imageProps} />;

const Footer: React.FC<IFooterProps> = ({ hidePaymentLogosInFooter = false }) => {
  const { t, ready } = useTranslation(["common"]);
  const chunkedArray: IFooterItem[][] = useFooterMenuItems();

  const renderFooterLinkItem = (item) => {
    if (item.external) {
      return <ExternalLink item={item} />;
    } else {
      return <InternalLink item={item} />;
    }
  };

  const lazyImagePlaceholder = ({ ref }) => <div ref={ref} />;

  return (
    <footer css={styles.footer()}>
      <div css={styles.footerContainer()}>
        {!hidePaymentLogosInFooter && (
          <PaymentLogos paymentIcons={ready && t("paymentIcons", { returnObjects: true })} />
        )}
        <div>
          <div css={styles.footerContainerContent()}>
            <Grid container>
              <Grid cssStyles={styles.column()} width={8}>
                <p css={styles.footerMissionStatement}>
                  <span data-nosnippet>{t("footer.missionStatement")}</span>
                </p>
              </Grid>
              <Grid cssStyles={styles.column()} width={4}>
                <div css={styles.footerSslWrapper()}>
                  <div>
                    <LazyImage placeholder={lazyImagePlaceholder} actual={Image} src={Icons.security} />
                  </div>
                  <p css={styles.text()}>{t("footer.sslInfo")}</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div css={styles.footerBorder()}>
          <div css={styles.footerContainerContent()}>
            <nav css={styles.footerNav()}>
              <Grid container>
                {chunkedArray &&
                  chunkedArray.map((chunk: IFooterItem[]) => (
                    <Grid width={4} key={chunk[0].text} cssStyles={styles.column()}>
                      <ul css={styles.footerMenuColumn()}>
                        {chunk.map((item: IFooterItem) => (
                          <li css={styles.footerMenuItem()} key={`footer-item-${item.text}`}>
                            {item.customFooterItem ? <CustomElement item={item} /> : renderFooterLinkItem(item)}
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  ))}
              </Grid>
            </nav>
          </div>
        </div>
        <div css={styles.footerBorder()}>
          <div css={styles.footerContainerContent()}>
            <div css={styles.footerBottomWrapper()}>
              <p css={styles.text()}>
                {t("footer.copyright")} {new Date().getFullYear()}, {t("footer.carfax")}
              </p>
              <SocialMediaLinks facebook twitter instagram youtube linkedin />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
