import { LanguageResolver } from "../i18n/language-resolver";
import { IDefaultSampleReportsFields, IPreviewDetailsOfRecordCheckFields } from "./types/generated/contentful";
import { ContentfulClientApi } from "contentful";
import { ContentType, reduceAssetToImage } from "./types/content-type";
import { contentfulClient } from "./contentful-client";
import { DefaultSampleReport } from "./PageModularModule";

export default class ContentfulApi {
  private static instance: ContentfulApi;
  private client: ContentfulClientApi;

  private constructor() {
    this.client = contentfulClient.getInstance();
  }

  public static getInstance(): ContentfulApi {
    if (ContentfulApi.instance) {
      return ContentfulApi.instance;
    }
    ContentfulApi.instance = new ContentfulApi();

    return ContentfulApi.instance;
  }

  public async getPreviewDetailsOfRecordCheck(locale: string) {
    try {
      const contents = await this.client.getEntries<IPreviewDetailsOfRecordCheckFields>({
        content_type: ContentType.PreviewDetailsOfRecordCheck,
        locale: locale || LanguageResolver.getCurrentLocale(),
      });

      return contents.items;
    } catch (error) {
      return [];
    }
  }

  public async getSimpleStaticPage(locale: string, slug: string | string[]) {
    try {
      const entries = await this.client.getEntries({
        content_type: ContentType.SimpleStaticPage,
        locale: locale || LanguageResolver.getCurrentLocale(),
        // eslint-disable-next-line:no-duplicate-string
        "fields.slug": slug,
      });
      return this.reducerSimpleStaticPage(entries);
    } catch (error) {
      return null;
    }
  }

  private reducerSimpleStaticPage(data) {
    return {
      ...data?.items?.[0].fields,
    };
  }

  public async getOptionalAssets(locale: string): Promise<DefaultSampleReport> {
    try {
      const sampleReports = await this.client.getEntries<IDefaultSampleReportsFields>({
        content_type: ContentType.DefaultSampleReports,
        locale: locale || LanguageResolver.getCurrentLocale(),
      });
      const data = sampleReports.items[0].fields;

      const defaultSampleReports = {
        sampleReportMobile: data.sampleReportMobile ? reduceAssetToImage(data.sampleReportMobile) : null,
        sampleReportMobileX2: data.sampleReportMobileX2 ? reduceAssetToImage(data.sampleReportMobileX2) : null,
        sampleReportDesktop: data.sampleReportDesktop ? reduceAssetToImage(data.sampleReportDesktop) : null,
        sampleReportDesktopX2: data.sampleReportDesktopX2 ? reduceAssetToImage(data.sampleReportDesktopX2) : null,
      };
      return defaultSampleReports;
    } catch (error) {
      return null;
    }
  }
}
