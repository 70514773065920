import dynamic from "next/dynamic";
import { observer } from "app";
import AlertStore from "stores/alert.store";
import { styles } from "./Alerts.style";

const Alert = dynamic(() => import("./Alert"), {
  ssr: false,
});
interface IProps {}

const Alerts: React.FC<IProps> = observer(() => {
  const alerts = AlertStore.alerts;

  return (
    alerts.length > 0 && (
      <div id="alerts-wrapper" data-testid="alerts-wrapper" css={styles.divAlerts()}>
        <div css={styles.divBoxedLayout()}>
          {alerts.map((alert, index) => (
            <Alert
              key={`alert-${index}`}
              variant={alert.variant}
              message={alert.message}
              messageToTranslate={alert.messageToTranslate}
              closable={alert.closable}
              isSticky={true}
              onClickClose={onClickClose.bind(this, index)}
              productType={alert.product}
              paymentMethod={alert.paymentMethod}
            />
          ))}
        </div>
      </div>
    )
  );
});

const onClickClose = (index: number) => {
  AlertStore.remove(index, 1);
};

export default Alerts;
export { onClickClose };
