import { EnvironmentVariables } from "./environment-variables";

enum Ports {
  HTTPS = 443,
  HTTP = 80,
}

const getSitePort = () => {
  const port = EnvironmentVariables.getWebsiteHostPort();
  const hostIsHttpsButPortIsDifferentThan443 = EnvironmentVariables.isWebsiteHostHttps() && Ports.HTTPS !== port;
  const hostIsHttpButPortIsDifferentThan80 = !EnvironmentVariables.isWebsiteHostHttps() && Ports.HTTP !== port;

  let websitePort = "";

  if (hostIsHttpsButPortIsDifferentThan443 || hostIsHttpButPortIsDifferentThan80) {
    websitePort = getPortString(port);
  }

  return websitePort;
};

const getPortString = (port: number) => `:${port}`;

export const sitePort = {
  getSitePort,
};
