import { Entry } from "../../app";
import { IBlogPostFields, ICategoryFields, INewsPostFields } from "./generated/contentful";
import { Asset } from "contentful";

export enum ContentType {
  BlogPost = "blogPost",
  NewsPost = "newsPost",
  SimpleStaticPage = "staticPages",
  Category = "category",
  PreviewDetailsOfRecordCheck = "previewDetailsOfRecordCheck",
  PackagesPricing = "packagesPricing",
  PageModular = "pageModular",
  JobCategory = "jobCategory",
  DefaultSampleReports = "defaultSampleReports",
}

export enum ModuleContentType {
  ModuleSampleVhr = "moduleSampleVhr",
  ModuleReportFeatures = "moduleReportFeatures",
  ModuleBanner = "moduleBanner",
  ModulePartnerLogos = "modulePartnerLogos",
  ModuleTextWithImage = "moduleTextWithImage",
  ModuleGallery = "moduleGallery",
  ModuleHRContactArea = "ModuleHRContactArea",
  ModuleUsps = "ModuleUsps",
  ModuleCustom = "moduleCustom",
  ModuleAccordionCards = "moduleAccordionCards",
  ModuleDeviceCarousel = "moduleDeviceCarousel",
  ModuleDisclaimer = "moduleDisclaimer",
  ModuleCampaignBanner = "moduleCampaignBanner",
  ModulePackageWithoutVin = "modulePackageWithoutVin",
  ModuleGeneralVinSearch = "moduleGeneralVinSearch",
  ModuleGoogleReviews = "moduleGoogleReviews",
  ModuleLandingPageVoucherEntry = "landingPageVoucherEntry",
  ModuleBolloCalculator = "bolloCalculator",
}

export interface IAllBlogPosts {
  entries: Array<Entry<IBlogPostFields>>;
  total: number;
  limit: number;
  skip: number;
}

export interface IAllCategories {
  entries: Array<Entry<ICategoryFields>>;
  total: number;
  limit: number;
  skip: number;
}

export interface IEntriesFilters {
  limit?: number;
  skip?: number;
  currentPost?: Entry<IBlogPostFields> | Entry<INewsPostFields>;
  currentCategory?: Entry<ICategoryFields>;
  order?: string;
}

export interface Image {
  url: string;
  title?: string;
  description?: string;
  width?: number;
  height?: number;
}

export interface Video {
  url: string;
  title?: string;
  description?: string;
}

export const reduceAssetToImage = (asset: Asset): Image => {
  return {
    url: `${asset.fields.file.url}`,
    title: asset.fields.title || "",
    description: asset.fields.description || "",
    width: asset.fields.file.details.image?.width,
    height: asset.fields.file.details.image?.height,
  };
};
export const reduceAssetToVideo = (asset: Asset): Video => {
  return {
    url: asset.fields.file.url,
    title: asset.fields.title || "",
    description: asset.fields.description || "",
  };
};
