import { css } from "@emotion/react";

export const styles = {
  root: () =>
    css({
      display: "inline-flex",
    }),
  reactSvgIcon: () =>
    css({
      display: "flex",
      div: {
        display: "flex",
        flex: 1,
        svg: {
          flex: 1,
        },
      },
    }),
};
