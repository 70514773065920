const spacingBaseUnit = {
  mobile: 8,
};

const spacing = {
  baseUnitMobile1: `${spacingBaseUnit.mobile}px`,
  baseUnitMobile2: `${spacingBaseUnit.mobile * 2}px`,
  baseUnitMobile3: `${spacingBaseUnit.mobile * 3}px`,
  baseUnitMobile4: `${spacingBaseUnit.mobile * 4}px`,
  baseUnitMobile5: `${spacingBaseUnit.mobile * 5}px`,
  baseUnitMobile6: `${spacingBaseUnit.mobile * 6}px`,
  baseUnitMobile7: `${spacingBaseUnit.mobile * 7}px`,
  baseUnitMobile8: `${spacingBaseUnit.mobile * 8}px`,
  baseUnitMobile9: `${spacingBaseUnit.mobile * 9}px`,
  baseUnitMobile10: `${spacingBaseUnit.mobile * 10}px`,
  baseUnitMobile14: `${spacingBaseUnit.mobile * 14}px`,
  baseUnitMobile15: `${spacingBaseUnit.mobile * 15}px`,
  baseUnitMobile20: `${spacingBaseUnit.mobile * 20}px`,
  baseUnitMobile30: `${spacingBaseUnit.mobile * 30}px`,
};

export { spacing, spacingBaseUnit };
