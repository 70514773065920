import { css } from "@emotion/react";

import { fontFamilies, letterSpacings, fontSizes, lineHeights } from "../styles/font.style";
import { colors } from "../styles/color.style";
import { spacing } from "../styles/element.spacing";
import { mediaQuery } from "../styles/mediaquery.style";

const textsSharedStyles = {
  color: colors.headlineGrey.hex,
  fontSize: fontSizes.body.horse,
  lineHeight: lineHeights.body.horse,
  letterSpacing: letterSpacings.body.horse,
};
export const stylesWrapper = css({
  fontFamily: `'${fontFamilies.Roboto}', 'fb-MacFont', 'fb-Ubuntu', 'fb-Helvetica', sans-serif`,
  fontSize: fontSizes.body.horse,
  lineHeight: lineHeights.body.horse,
  fontWeight: 400,
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
  margin: 0,
  padding: 0,
  overflowX: "hidden",
  background: colors.white.hex,
  color: colors.black.hex,
});

export const styles = css({
  body: {
    ...stylesWrapper,
  },
  a: {
    color: colors.linkBlue.hex,
    textDecoration: "none",
    fontWeight: 500,
    fontStyle: "normal",
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline",
    },
  },
  ":focus": {
    outline: `${colors.linkBlue.hex} auto 2px`,
  },
  h1: {
    color: colors.headlineGrey.hex,
    fontWeight: 700,
    marginBottom: spacing.baseUnitMobile4,
    fontSize: fontSizes.heading.H1.mobile,
    lineHeight: lineHeights.heading.H1.mobile,
    letterSpacing: letterSpacings.heading.H1.mobile,
    [mediaQuery.desktopOrLarger]: {
      fontSize: fontSizes.heading.H1.desktop,
      lineHeight: lineHeights.heading.H1.desktop,
      letterSpacing: letterSpacings.heading.H1.desktop,
    },
  },
  h2: {
    color: colors.headlineGrey.hex,
    marginBottom: spacing.baseUnitMobile3,
    fontWeight: 700,
    fontSize: fontSizes.heading.H2.mobile,
    lineHeight: lineHeights.heading.H2.mobile,
    letterSpacing: letterSpacings.heading.H2.mobile,
    [mediaQuery.desktopOrLarger]: {
      fontSize: fontSizes.heading.H2.desktop,
      lineHeight: lineHeights.heading.H2.desktop,
      letterSpacing: letterSpacings.heading.H2.desktop,
    },
  },
  h3: {
    color: colors.headlineGrey.hex,
    marginBottom: spacing.baseUnitMobile1,
    fontWeight: 700,
    fontSize: fontSizes.heading.H3.mobile,
    lineHeight: lineHeights.heading.H3.mobile,
    letterSpacing: letterSpacings.heading.H3.mobile,
    [mediaQuery.desktopOrLarger]: {
      fontSize: fontSizes.heading.H3.desktop,
      lineHeight: lineHeights.heading.H3.desktop,
      letterSpacing: letterSpacings.heading.H3.desktop,
    },
  },
  h4: {
    color: colors.headlineGrey.hex,
    marginBottom: spacing.baseUnitMobile1,
    fontWeight: 700,
    fontSize: fontSizes.heading.H4.mobile,
    lineHeight: lineHeights.heading.H4.mobile,
    letterSpacing: letterSpacings.heading.H4.mobile,
    [mediaQuery.desktopOrLarger]: {
      fontSize: fontSizes.heading.H4.desktop,
      lineHeight: lineHeights.heading.H4.desktop,
      letterSpacing: letterSpacings.heading.H4.desktop,
    },
  },
  h5: {
    color: colors.headlineGrey.hex,
    marginBottom: spacing.baseUnitMobile1,
    fontWeight: 700,
    fontSize: fontSizes.heading.H5.mobile,
    lineHeight: lineHeights.heading.H5.mobile,
    letterSpacing: letterSpacings.heading.H5.mobile,
    [mediaQuery.desktopOrLarger]: {
      fontSize: fontSizes.heading.H5.desktop,
      lineHeight: lineHeights.heading.H5.desktop,
      letterSpacing: letterSpacings.heading.H5.desktop,
    },
  },
  h6: {
    color: colors.headlineGrey.hex,
    marginBottom: spacing.baseUnitMobile1,
    fontWeight: 700,
    fontSize: fontSizes.heading.H6.mobile,
    lineHeight: lineHeights.heading.H6.mobile,
    letterSpacing: letterSpacings.heading.H6.mobile,
    [mediaQuery.desktopOrLarger]: {
      fontSize: fontSizes.heading.H6.desktop,
      lineHeight: lineHeights.heading.H6.desktop,
      letterSpacing: letterSpacings.heading.H6.desktop,
    },
  },
  picture: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "640px",
    alignItems: "center",
    img: {
      width: "100%",
    },
  },
  figcaption: {
    ...textsSharedStyles,
    maxWidth: "90%",
    fontSize: fontSizes.body.horse,
    lineHeight: lineHeights.body.horse,
    fontStyle: "italic",
    padding: "10px",
  },
  ol: {
    ...textsSharedStyles,
  },
  ul: {
    ...textsSharedStyles,
  },
  i: {
    fontStyle: "italic",
  },
  span: {
    ...textsSharedStyles,
  },
  strong: {
    fontWeight: "bolder",
  },
  p: {
    ...textsSharedStyles,
    marginBottom: spacing.baseUnitMobile3,
  },
  blockquote: {
    margin: "0 0 0 30px",
    padding: "0 0 0 15px",
    borderLeft: `6px solid ${colors.paleBlue.hex}`,
  },
});
