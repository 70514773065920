import { Link } from "app";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import { AdditionalLabel } from "@carfax/web-ui/components/AdditionalLabel";
import { IFooterItem } from "../Footer";
import { styles } from "../Footer.style";

interface Props {
  item: IFooterItem;
}

const InternalLink: React.FC<Props> = ({ item }) => {
  const routes = useRoutes();
  return (
    <Link data-testid={item.testSelector} css={styles.footerMenuItemLinkWrapper()} href={routes[item.slug]} passHref>
      <>
        <span css={styles.footerMenuItemLink()}>{item.text}</span>
        {item.additionalLabel && <AdditionalLabel text={item.additionalLabel} />}
      </>
    </Link>
  );
};

export default InternalLink;
