import { css } from "@emotion/react";
import { mediaQuery, spacing } from "../../core/styles";

export const styles = {
  flexContainer: () =>
    css({
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      "> div": {
        padding: `0 ${spacing.baseUnitMobile2}`,
        [mediaQuery.smallerThanTablet]: {
          padding: `0`,
        },
      },
    }),
  flexItem: (width) =>
    css({
      flexBasis: "100%",
      [mediaQuery.tabletOrLarger]: {
        flexBasis: width ? `${(width / 12) * 100}%` : "100%",
        maxWidth: width ? `${(width / 12) * 100}%` : "100%",
      },
    }),
};
