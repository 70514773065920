export const ONE_SECOND_IN_MILLISECONDS = 1000;
export class IntervalService {
  private listOfFunctions: Function[] = [];
  private timeInMs: number;

  constructor(timeInMs = ONE_SECOND_IN_MILLISECONDS) {
    this.timeInMs = timeInMs;
    setInterval(this.run.bind(this), this.timeInMs);
  }

  public run() {
    this.listOfFunctions.forEach((registeredFunction) => registeredFunction());
  }

  public register(registeredFunction: Function): Function {
    this.listOfFunctions.push(registeredFunction);

    return this.unregister.bind(this, registeredFunction);
  }

  public unregister(registeredFunction: Function) {
    const notInArray = -1;
    const indexOfRegisteredFunction = this.listOfFunctions.indexOf(registeredFunction);
    if (indexOfRegisteredFunction != notInArray) {
      this.listOfFunctions.splice(indexOfRegisteredFunction, 1);
    }
  }
}
