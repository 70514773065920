const dimension = {
  header: {
    height: 80,
    mobileHeight: 64,
    mobileHeightWithoutVin: 56.5,
  },
};

const zIndex = {
  maxZIndex: 1000,
  header: {
    translucentBackground: 99,
    wrapper: 100,
    navigation: 101,
    subMenu: 103,
    vinAlert: 102,
  },
  modal: 103,
  vinWidget: {
    fox: 98,
  },
  highlight: 9,
};

export { dimension, zIndex };
