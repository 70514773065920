import { content } from "@carfax/web-ui/core";
import { action, makeObservable, observable } from "mobx";

interface IWindow {
  width: number;
  height: number;
}

interface IWindowScrollPosition {
  x: number;
  y: number;
}

class BrowserStore {
  @observable
  public window: IWindow;

  @observable
  public scrollPosition: IWindowScrollPosition;

  @observable
  public isDesktop: boolean;

  @observable
  public isTablet: boolean;

  @observable
  public isTabletAndLarger: boolean;

  @observable
  public isScrollingUp = false;

  private pastScrollYPosition = 0;

  constructor() {
    makeObservable(this);
    this.setWindow = this.setWindow.bind(this);
    this.setScrollPosition = this.setScrollPosition.bind(this);
    this.setIsDesktop = this.setIsDesktop.bind(this);
    this.setIsTablet = this.setIsTablet.bind(this);
    this.setIsTabletAndLarger = this.setIsTabletAndLarger.bind(this);
    this.setIsScrollingUp = this.setIsScrollingUp.bind(this);

    this.setWindow();
    this.setScrollPosition();
    this.setIsScrollingUp();
    this.onWindowResize();
    this.onScroll();
    this.setIsDesktop();
    this.setIsTablet();
    this.setIsTabletAndLarger();
  }

  @action
  public setWindow() {
    // `window` object is not available in SSR, but it a string with value `undefined`
    if (typeof window === `undefined`) {
      this.window = {
        width: undefined,
        height: undefined,
      };
      return;
    }

    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  @action
  public setIsDesktop() {
    this.isDesktop = this.window.width >= content.width;
  }

  @action
  public setIsTablet() {
    this.isTablet = this.window.width >= content.tabletWidth && this.window.width <= content.width;
  }

  @action
  public setIsTabletAndLarger() {
    this.isTabletAndLarger = this.window.width >= content.tabletWidth;
  }

  @action
  public setScrollPosition() {
    // `window` object is not available in SSR, but it a string with value `undefined`
    if (typeof window === `undefined`) {
      this.scrollPosition = {
        x: undefined,
        y: undefined,
      };
      return;
    }

    this.scrollPosition = {
      x: window.scrollX,
      y: window.scrollY,
    };
  }

  @action
  public setIsScrollingUp() {
    this.isScrollingUp = this.pastScrollYPosition > this.scrollPosition.y;
    this.pastScrollYPosition = this.scrollPosition.y;
  }

  private onWindowResize() {
    // `window` object is not available in SSR, but it a string with value `undefined`
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.setWindow);
      window.addEventListener("resize", this.setIsDesktop);
      window.addEventListener("resize", this.setIsTabletAndLarger);
    }
  }

  private onScroll() {
    // `window` object is not available in SSR, but it a string with value `undefined`
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", this.setScrollPosition);
      window.addEventListener("scroll", this.setIsScrollingUp);
    }
  }
}

export default new BrowserStore();
export { BrowserStore };
