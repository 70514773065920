import { Country } from "./../../i18n/country";
import { IPricingResponse } from "./types";

export const calculateRecords = (pricingResponse: IPricingResponse) => {
  const records = {
    local: 0,
    eu: 0,
    us: 0,
  };
  const recordCheck = pricingResponse.recordCheck;
  if (recordCheck.presentCountry !== Country.USA) {
    records.local = recordCheck.recordsPerCountry?.[recordCheck.presentCountry] || 0;
  }

  const presentCountry = recordCheck.recordsPerCountry?.[recordCheck.presentCountry] || 0;
  const usRecords = recordCheck.recordsPerCountry?.us || 0;
  records.eu =
    recordCheck.presentCountry === Country.USA
      ? recordCheck.displayRecords - presentCountry
      : recordCheck.displayRecords - presentCountry - usRecords;
  records.us = recordCheck.externalReports?.us?.displayRecords || 0;

  return records;
};
