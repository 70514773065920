import { IFooterItem } from "../Footer";
import { styles } from "../Footer.style";

interface Props {
  item: IFooterItem;
}

const ExternalLink: React.FC<Props> = ({ item }) => (
  <a href={item.href} target="_blank" rel="noreferrer" css={styles.footerMenuItemLink()}>
    {item.text}
  </a>
);

export default ExternalLink;
