function indexZeroOrSameSize(index: number, chunkSize: number) {
  return index % chunkSize;
}

function arrayChunk(chunkSize: number, inputArr: any[]) {
  return inputArr.reduce((acc, curr, i) => {
    if (!indexZeroOrSameSize(i, chunkSize)) {
      acc.push(inputArr.slice(i, i + chunkSize));
    }
    return acc;
  }, []);
}

export { arrayChunk };
