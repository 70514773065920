import { useRouter } from "next/router";
import { routesUtil } from "domain/Routing/utils/routesUtil";
import { useSlug } from "./useSlug";

export const useTranslatableRoutes = (languages) => {
  const { pathname, asPath } = useRouter();
  const foundCustomRouteKey = useSlug();
  const queryParams: string = routesUtil.getQueryPartFromPath(asPath);

  let newRoute = pathname;
  const jobDescriptionPage = "[jobName]";
  const isJobPageDescription = newRoute.indexOf(jobDescriptionPage) >= 0;
  if (isJobPageDescription && typeof window !== "undefined") {
    // pass the actual location
    const path = window.location.pathname.split("/");
    newRoute = `/careers/${path[path.length - 1]}`;
    return languages.reduce((acc, lang) => ({ ...acc, [lang]: newRoute.concat(queryParams) }), {});
  }

  return languages.reduce(
    (acc, lang) => ({
      ...acc,
      [lang]: routesUtil.getCustomRouteForLanguage(foundCustomRouteKey, lang).concat(queryParams),
    }),
    {}
  );
};
