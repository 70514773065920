/**
 * This file exports all typical methods, typings etc, which you need in Next.js page (or general component).
 * The aim of this abstraction is to avoid confusion from which package e.g. `<Link>` must be imported.
 */
// eslint-disable @typescript-eslint/interface-name-prefix

// Next.js
import { INextPageProps as INextPageProps_Imported } from "./types";
export interface INextPageProps extends INextPageProps_Imported {}

export * from "./i18n/components";
export { useTranslation, Trans, withTranslation } from "next-i18next";
export type { WithTranslation } from "next-i18next";

// Emotion
export { css } from "@emotion/react";

// Mobx
export { observer } from "mobx-react";

// Contentful
import { Entry as Entry_Imported } from "contentful";
export interface Entry<T> extends Entry_Imported<T> {}
