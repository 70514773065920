class LocalStorage {
  private key: string;
  constructor(key) {
    this.key = key;
  }
  public saveDataToLocalStorage(data) {
    if (typeof localStorage === "undefined") {
      return null;
    }

    localStorage.setItem(this.key, data);
  }

  public loadDataFromLocalStorage<T>() {
    if (typeof localStorage === "undefined") {
      return null;
    }

    const data = <T>JSON.parse(localStorage.getItem(this.key));
    return data;
  }
}

export { LocalStorage };
