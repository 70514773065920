import { useTranslation } from "app";
import { useMemo } from "react";
import { arrayChunk } from "../utils/arrayChunk";

export function useFooterMenuItems() {
  const { t } = useTranslation(["common"]);
  return useMemo(() => {
    const menuItems = [
      { external: true, text: t("footer.menu.contact.text"), href: t("footer.menu.contact.href") },
      { slug: "press", text: t("footer.menu.press.text") },
      { slug: "careers", text: t("footer.menu.careers.text"), additionalLabel: t("footer.hiringLabel") },
      { slug: "termsAndConditions", text: t("footer.menu.terms.text") },
      { slug: "imprint", text: t("footer.menu.imprint.text") },
      { slug: "privacyHub", text: t("footer.menu.privacyNotice.text") },
      { external: true, href: t("footer.menu.portal.href"), text: t("footer.menu.portal.text") },
      {
        customFooterItem: true,
        text: `<!-- OneTrust Cookies Settings button start -->
        <button id="ot-sdk-btn" class="ot-sdk-show-settings"> Cookie Settings</button>
        <!-- OneTrust Cookies Settings button end -->`,
      },
    ];
    const columnWidth = 3;
    const chunkSize = Math.ceil(menuItems.length / columnWidth);
    return arrayChunk(chunkSize, menuItems);
  }, [t]);
}
