import { useRouter } from "next/router";
import { useEffect } from "react";

export const useRouterFocusReset = () => {
  const router = useRouter();

  interface RouteChangeEvent {
    shallow: boolean;
  }

  useEffect(() => {
    const handleRouteChange = ({ shallow }: RouteChangeEvent) => {
      if (!shallow) {
        document.body.tabIndex = 0;
        document.body.focus();
        document.body.tabIndex = -1;
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};
