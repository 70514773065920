import { createContext, PropsWithChildren } from "react";

import stores from "../stores";

/**
 * Context for: Mobx stores
 */
const StoreContext = createContext(stores);

const StoreProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
);

export { StoreContext, StoreProvider };
