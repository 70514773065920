import { sitePort } from "./site-port";
import { EnvironmentVariables } from "./environment-variables";

function getSiteUrl() {
  const protocol = EnvironmentVariables.isWebsiteHostHttps() ? `https://` : `http://`;
  const wwwPrefix = EnvironmentVariables.isWebsiteHostPrefixedWithWww() ? `www.` : "";
  const host = EnvironmentVariables.getWebsiteHost();
  const websitePort = sitePort.getSitePort();

  return `${protocol}${wwwPrefix}${host}${websitePort}`;
}

export { getSiteUrl };
