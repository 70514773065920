import { Language, Locales, Country } from "./country";

const locales = [
  Locales.USA,
  Locales.DUTCH,
  Locales.GERMAN,
  Locales.ITALIAN,
  Locales.POLISH,
  Locales.SLOVENE,
  Locales.FRENCH,
  Locales.RUSSIAN,
  Locales.SPANISH,
  Locales.SWEDISH,
];

const localesForSitemap = [
  Locales.DUTCH,
  Locales.GERMAN,
  Locales.ITALIAN,
  Locales.POLISH,
  Locales.SLOVENE,
  Locales.FRENCH,
  Locales.RUSSIAN,
  Locales.SPANISH,
  Locales.SWEDISH,
];

const licensePlateBasedLocales = [Locales.SPANISH, Locales.ITALIAN, Locales.DUTCH, Locales.SWEDISH];

const allowedLanguagesInCareersPath: string[] = [Language.GERMAN];
const forbiddenLanguagesInPressPath: string[] = [Language.FRENCH];

const localeSubpaths = {};
localeSubpaths[Locales.USA] = Language.ENGLISH;
localeSubpaths[Locales.DUTCH] = Language.DUTCH;
localeSubpaths[Locales.GERMAN] = Language.GERMAN;
localeSubpaths[Locales.ITALIAN] = Language.ITALIAN;
localeSubpaths[Locales.POLISH] = Language.POLISH;
localeSubpaths[Locales.SLOVENE] = Language.SLOVENE;
localeSubpaths[Locales.FRENCH] = Language.FRENCH;
localeSubpaths[Locales.RUSSIAN] = Language.RUSSIAN;
localeSubpaths[Locales.SPANISH] = Language.SPANISH;
localeSubpaths[Locales.SWEDISH] = Language.SWEDISH;

const localesCountryCodes = {};
localesCountryCodes[Locales.USA] = Country.GREAT_BRITAIN;
localesCountryCodes[Locales.DUTCH] = Country.NETHERLANDS;
localesCountryCodes[Locales.GERMAN] = Country.GERMANY;
localesCountryCodes[Locales.ITALIAN] = Country.ITALY;
localesCountryCodes[Locales.POLISH] = Country.POLAND;
localesCountryCodes[Locales.SLOVENE] = Country.SLOVENIA;
localesCountryCodes[Locales.FRENCH] = Country.FRANCE;
localesCountryCodes[Locales.RUSSIAN] = Country.RUSSIA;
localesCountryCodes[Locales.SPANISH] = Country.SPAIN;
localesCountryCodes[Locales.SWEDISH] = Country.SWEDEN;

const getDefaultLocale = () => locales[0];

const getFallbackLanguage = () => localeSubpaths[locales[0]];

const getFallbackCountry = () => localesCountryCodes[locales[0]];

const isLanguageAllowedInCareersPath = (language: string): boolean => {
  return allowedLanguagesInCareersPath.includes(language);
};

const isLanguageForbiddenInPressPath = (language: string): boolean => {
  return forbiddenLanguagesInPressPath.includes(language);
};

const mapLocaleToLanguageShort = (locale: string): string => localeSubpaths[locale] || getFallbackLanguage();

const mapLocaleToCountryCode = (locale: string): string => localesCountryCodes[locale] || getFallbackCountry();

const mapLocaleToLanguageUrlSubpath = (locale: string): string => {
  if (!(locales as string[]).includes(locale)) {
    locale = Locales.USA;
  }
  return locale === Locales.USA ? "" : `/${mapLocaleToLanguageShort(locale)}`;
};

const mapLanguageToLanguageUrlSubpath = (lang: string): string =>
  lang === languageConfig.getFallbackLanguage() ? "" : `/${lang}`;

const mapLanguageToLocale = (language: string): string => {
  return Object.keys(localeSubpaths).find((key) => localeSubpaths[key] == language) || getDefaultLocale();
};

const getLocales = () => [...locales];

const getLocalesForSitemap = () => [...localesForSitemap];

const getLanguages = (): string[] => Object.values(localeSubpaths);
const isLanguageAllowedInPath = (language: string): boolean => {
  return language !== getFallbackLanguage() && getLanguages().includes(language);
};

const isLanguageAllowedInPathOrFallbackLanguage = (language: string): boolean => {
  return getLanguages().includes(language) || language === getFallbackLanguage();
};

const isLocaleLicensePlateBased = (locale: string): boolean => {
  return licensePlateBasedLocales.includes(locale as Locales);
};

export const languageConfig = {
  mapLocaleToLanguageUrlSubpath,
  mapLanguageToLocale,
  getDefaultLocale,
  getFallbackLanguage,
  isLanguageAllowedInCareersPath,
  isLanguageForbiddenInPressPath,
  getLocales,
  getLocalesForSitemap,
  getLanguages,
  mapLocaleToLanguageShort,
  mapLanguageToLanguageUrlSubpath,
  isLanguageAllowedInPath,
  isLanguageAllowedInPathOrFallbackLanguage,
  mapLocaleToCountryCode,
  isLocaleLicensePlateBased,
};
