import App, { AppContext } from "next/app";
import { Toggles } from "../../server/feature-toggles/toggles";
import Layout from "components/Layout/Layout";
import { OverallProvider } from "contexts";
import { googleTagManager } from "domain/SeoAndAnalytics/GoogleTagManager/utils/google-tag-manager";
import { appWithTranslation } from "next-i18next";
import { withRouter } from "next/router";
import { languageConfig } from "i18n/language-config";
import { CacheProvider } from "@emotion/react";
import { cache } from "../emotionCache";
import { markerIoManager } from "../domain/MarkerIo/markerIoManager";
import { routesUtil } from "../domain/Routing/utils/routesUtil";
import ContentfulApi from "contentful/contentful-api.class";
import { DefaultSampleReport } from "contentful/PageModularModule";
import { fetchFeatureToggles } from "utils/featureTogglesUtils";
import { Auth0Provider } from "@auth0/auth0-react";
import { EnvironmentVariables } from "utils/config/environment-variables";
import { TrackingCtxProvider } from "domain/SeoAndAnalytics/GoogleAnalytics/context/TrackingContext";
import React from "react";

class MyApp extends App<
  { ids: string[]; toggles: Toggles; defaultSampleReports: DefaultSampleReport; referrer: string },
  {}
> {
  state = {
    key: 0,
    toggles: null,
  };

  private withMarkerIoWidget: boolean;

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.setWithMarkerIoWidget();
    if (this.withMarkerIoWidget) {
      markerIoManager.loadMarkerIo();
    }
    void googleTagManager.loadTagManager();
  }

  private setWithMarkerIoWidget(): void {
    const queryMarker = this.props.router.query?.marker as string;
    const routerPath = this.props.router.asPath;
    this.withMarkerIoWidget = MyApp.getMarker(queryMarker, routerPath);
  }

  private static getMarker(queryMarker: string, path: string): boolean {
    const marker = queryMarker || routesUtil.getMarkerPartFromPath(path);
    return marker ? marker.toLowerCase() == "true" : false;
  }

  public render() {
    const { Component, pageProps, toggles, defaultSampleReports } = this.props;
    const pagePropsWithAdditionalProps = { ...pageProps, toggles, defaultSampleReports };
    const auth0Domain = EnvironmentVariables.getAuth0Domain();
    const auth0ClientId = EnvironmentVariables.getAuth0ClientId();

    return (
      <CacheProvider value={cache}>
        <OverallProvider>
          <TrackingCtxProvider>
            <Auth0Provider domain={auth0Domain} clientId={auth0ClientId}>
              <Layout {...pagePropsWithAdditionalProps}>
                <Component {...pagePropsWithAdditionalProps} />
              </Layout>
            </Auth0Provider>
          </TrackingCtxProvider>
        </OverallProvider>
      </CacheProvider>
    );
  }
}

MyApp.getInitialProps = async (ctx: AppContext) => {
  const initialProps = await App.getInitialProps(ctx);
  const toggles = await fetchFeatureToggles();

  const queryLang = ctx.router.query?.lang as string;
  const routerPath = ctx.router.asPath;
  const locale = languageConfig.mapLanguageToLocale(queryLang || routesUtil.getLanguagePartFromPath(routerPath));

  const defaultSampleReports = await ContentfulApi.getInstance().getOptionalAssets(locale);
  return {
    ...initialProps,
    toggles,
    defaultSampleReports: defaultSampleReports,
  };
};
export default withRouter(appWithTranslation(MyApp as any));
