import { IFooterItem } from "../Footer";
import { styles } from "../Footer.style";

interface Props {
  item: IFooterItem;
}

const CustomElement: React.FC<Props> = ({ item }) => (
  <div
    css={styles.footerMenuItemLink()}
    dangerouslySetInnerHTML={{
      __html: item.text,
    }}
  />
);

export default CustomElement;
