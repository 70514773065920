import { Log } from "./log";
import { Toggles } from "../../server/feature-toggles/toggles";
import { EnvironmentVariables } from "./config/environment-variables";
import memoryCache from "memory-cache";
import { PHASE_PRODUCTION_BUILD } from "next/dist/shared/lib/constants";
import { environment } from "./config/environment";

const TOGGLE_MEMORY_KEY = "toggles_data";
const cacheTimeoutMilliseconds = 2 * 60 * 1000;

export const defaultToggles: Toggles = {
  voucherComponentPaymentPage: true,
  legitimateInterestTogglesOnReportPull: true,
  invoiceFormPricingPage: true,
  reportPreviewPageEN: false,
  reportPreviewPageDE: false,
  reportPreviewPageIT: false,
  reportPreviewPageES: true,
  reportPreviewPageFR: false,
  reportPreviewPageNL: true,
  reportPreviewPagePL: true,
  reportPreviewPageRU: false,
  reportPreviewPageSL: false,
  reportPreviewPageSV: true,
  reportLoaderIT: false,
};

const getToggleMiddlewareUrl = () => {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return `https://${EnvironmentVariables.getWebsiteHost()}:${EnvironmentVariables.getWebsiteHostPort()}/api/v1/feature-toggles`;
  } else if (environment.isApplicationRunningInNodeModeDevelopment()) {
    return "";
  } else {
    return `http://${EnvironmentVariables.getWebsiteInternalHost()}:${EnvironmentVariables.getWebsiteInternalPort()}/api/v1/feature-toggles`;
  }
};

export const fetchFeatureToggles = async () => {
  if (memoryCache.get(TOGGLE_MEMORY_KEY)) {
    return memoryCache.get(TOGGLE_MEMORY_KEY);
  }
  const toggleUrl = getToggleMiddlewareUrl();

  if (toggleUrl) {
    try {
      const togglesData = await fetch(toggleUrl).then((response) => response.json());
      memoryCache.put(TOGGLE_MEMORY_KEY, togglesData, cacheTimeoutMilliseconds);
      return togglesData;
    } catch (e) {
      Log.error({ event: { message: "Toggles cannot be fetched", http: { status: 404 }, res: e } });
    }
  }
  return defaultToggles;
};
