import React, { PropsWithChildren } from "react";
import { styles } from "./Grid.style";
import { SerializedStyles } from "@emotion/react";

interface GridProps {
  container?: boolean;
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  cssStyles?: SerializedStyles;
}

export const Grid: React.FC<PropsWithChildren<GridProps>> = (props) => {
  const { children, container, width, cssStyles = "" } = props;
  return <div css={[container && styles.flexContainer(), styles.flexItem(width), cssStyles]}>{children}</div>;
};
