import { customRouteMap } from "domain/Routing/customRouteMap";
import { useRouter } from "next/router";
import { useLanguage } from "hooks/useLanguage";
import { routesUtil } from "domain/Routing/utils/routesUtil";

export const useSlug = () => {
  const { pathname, asPath } = useRouter();
  const lang = useLanguage();

  let route = undefined;
  if (pathname.endsWith("[lang]") || pathname.endsWith("[lang]/[pageName]")) {
    route = routesUtil.getRoutePartFromPath(asPath);
  } else {
    route = routesUtil.getRoutePartFromPath(pathname);
  }

  return Object.keys(customRouteMap).find((slug) => {
    if (!customRouteMap[slug].routes) {
      return route.indexOf(customRouteMap[slug].defaultRoute) >= 0;
    }
    return customRouteMap[slug].routes[lang] && route.indexOf(customRouteMap[slug].routes[lang]) >= 0;
  });
};
