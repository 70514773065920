import { css } from "@emotion/react";
import { colors, fontSizes } from "../../core";

export const styles = {
  root: () =>
    css({
      backgroundColor: colors.green.hex,
      padding: "0 4px 2px",
      borderRadius: "2px",
      marginLeft: "8px",
      color: colors.white.hex,
      fontSize: fontSizes.body.mouse,
      fontWeight: "bold",
      letterSpacing: "0.5px",
      lineHeight: "14px",
    }),
};
