import { PropsWithChildren } from "react";
import { styles } from "./Content.style";

const Content: React.FC<PropsWithChildren<{}>> = (props) => {
  const { children } = props;

  return <main css={styles.main()}>{children}</main>;
};

export default Content;
