export const depreciation = require("./images/usp/ic-depreciation.svg");
export const mileage = require("./images/usp/ic-mileage.svg");
export const accident = require("./images/usp/ic-accident.svg");
export const checklist = require("./images/usp/ic-checklist.svg");
export const number35 = require("./images/usp/ic-number35.svg");
export const database = require("./images/usp/ic-database.svg");
export const world = require("./images/usp/ic-world.svg");
export const checkpoint = require("./images/usp/ic-checkpoint.svg");
export const fingerTap = require("./images/usp/ic-finger-tap.svg");

export const americanexpress = require("./images/payment/logo-american-express.svg");
export const applepay = require("./images/payment/logo-apple-pay.svg");
export const blik = require("./images/payment/logo-blik.svg");
export const dinersinternational = require("./images/payment/logo-diners-international.svg");
export const discover = require("./images/payment/logo-discover.svg");
export const googlepay = require("./images/payment/logo-google-pay.svg");
export const ideal = require("./images/payment/logo-ideal.svg");
export const klarna = require("./images/payment/logo-klarna.svg");
export const maestro = require("./images/payment/logo-maestro.svg");
export const mastercard = require("./images/payment/logo-mastercard.svg");
export const paypal = require("./images/payment/logo-paypal.svg");
export const visa = require("./images/payment/logo-visa.svg");
export const postepay = require("./images/payment/logo-postepay.svg");

export const apple = require("./images/apple-logo.svg");

export const step1 = require("./images/steps/ic-step-1.svg");
export const step2 = require("./images/steps/ic-step-2.svg");
export const step3 = require("./images/steps/ic-step-3.svg");

export const arrowUp = require("./images/ic-arrow-up.svg");
export const book = require("./images/ic-book.svg");
export const menu = require("./images/ic-menu.svg");

export const chevron = require("./images/ic-chevron.svg");
export const search = require("./images/ic-search.svg");
export const circleCloseWhite = require("./images/ic-close-white.svg");
export const returnGuarantee = require("./images/ic-return-guarantee.svg");
export const customerSupport = require("./images/ic-customer-support.svg");
export const clipboard = require("./images/icon_clipboard.svg");
export const security2 = require("./images/ic-security-2.svg");
export const logout = require("./images/logout-24px-headline-grey.svg");
export const dashboard = require("./images/dashboard-24px.svg");
export const reports = require("./images/reports-24px.svg");
export const searchHeaderTopBar = require("./images/searchHeader2-24px.svg");
export const clear = require("./images/clear-24px-headline-grey.svg");
export const accountOutline = require("./images/account-outline-24px-white.svg");
export const account = require("./images/account-24pxheadline-grey.svg");
export const settings = require("./images/account-settings-24px.svg");
export const checkMark = require("./images/icon-check-green-24px.svg");
export const checkMarkBlue = require("./images/icon_checkmark_blue.svg");
export const helpOutline = require("./images/help-outline-24px-white.svg");
export const messageTail = require("./images/message-tail-25px.svg");
export const lightbulbGreenThin = require("./images/lightbulb-green-thin.svg");
export const close = require("./images/close.svg");
export const closeBlue = require("./images/close-blue.svg");
export const visibility = require("./images/visibility.svg");
export const visibilityOff = require("./images/visibility-off.svg");
export const arrowDropDown = require("./images/arrow-dropdown.svg");
export const arrowThin = require("./images/arrow-thin-dropdown.svg");
export const logoDarkMode = require("./images/logo-dark-mode.svg");
export const animatedSearch = require("./images/vin-search-icon-sprite-sheet-test-01.svg");
export const animatedArrowAfterClick = require("./images/vin-search-icon-click-sprite.svg");
export const quotationMark = require("./images/quotation-mark-blue-36x28px.svg");
export const vinSearchReportGrey = require("./images/report-icon-with-hand-grey.svg");
export const report = require("./images/vin-search-report-icon.svg");
export const note = require("./images/vhr-desktop-01.svg");
export const noteDoubleLine = require("./images/vhr-desktop-04.svg");
export const noteTripleLine = require("./images/vhr-desktop-08.svg");
export const loader = require("./images/loader.svg");
export const blogIcon = require("./images/icon_blog.svg");
export const supportCenterIcon = require("./images/icon_support_center.svg");
export const usImportIcon = require("./images/us-import.svg");
export const euImportIcon = require("./images/eu-import.svg");
export const mileagePreview = require("./images/ic-mileagePreview.svg");
export const autoTheft = require("./images/ic-autoTheft.svg");
export const inspection = require("./images/ic-inspection.svg");
export const rental = require("./images/ic-rental.svg");
export const taxi = require("./images/ic-taxi.svg");
export const ownerInformation = require("./images/ic-owner-information.svg");
export const financialCharges = require("./images/ic-financial-charges.svg");
export const numberOfOwners = require("./images/ic-number-of-owners.svg");
export const warningIcon = require("./images/warning-icon.svg");
export const loadingIcon = require("./images/loading-icon.svg");
export const circledCheckmark = require("./images/circledCheckmark.svg");
export const refreshIcon = require("./images/refresh-icon-link-blue-24px.svg");
export const acuteTrustIcon = require("./images/trust-icon-acute.svg");
export const creditCardTrustIcon = require("./images/trust-icon-credit-card.svg");
export const euroTrustIcon = require("./images/trust-icon-euro.svg");
export const lockTrustIcon = require("./images/trust-icon-lock.svg");
export const checkMarkDecoration = require("./images/check-mark-decor.svg");
export const checkboxMark = require("./images/checkboxMark.svg");
export const arrowUpward = require("./images/arrow-upward.svg");
export const arrowDownward = require("./images/arrow-downward.svg");
export const tickIconExpressCheckout = require("./images/tick-icon-express-checkout.svg");
export const reportBlue = require("./images/report-blue.svg");
export const checkMarkGreen = require("./images/check_circle.svg");
export const linkInvalidOrExpired = require("./images/link-invalid-or-expired.svg");

export const websiteUsers = require("./images/website-users.svg");
export const buyers = require("./images/buyers.svg");
export const businessCustomers = require("./images/business-customers.svg");
export const whistleBlowers = require("./images/whistle-blowers.svg");
export const carOwners = require("./images/car-owners.svg");
export const outlinedCheckmark = require("./images/outlined-Checkmark.svg");
export const acute = require("./images/acute.svg");
export const contacts = require("./images/contacts.svg");
export const published = require("./images/published.svg");
export const linkedinFanPage = require("./images/linkedin-fan-page.svg");

export const reportsCardSingle = require("./images/reports-single.svg");
export const reportsCardThree = require("./images/reports-three.svg");
export const reportsCardFive = require("./images/reports-five.svg");
export const arrowB2bSticker = require("./images/arrow-b2b-sticker.svg");
export const eyeIcon = require("./images/eye.svg");
export const appleLogoWhite = require("./images/apple-logo-white.svg");
export const lockClosed = require("./images/closed-lock.svg");
export const lockOpen = require("./images/open-lock.svg");
export const news = require("./images/ic-news.svg");
export const mailBlue = require("./images/ic-mail-blue.svg");
export const security = require("./images/ic-security.svg");
