import { action, makeObservable, observable } from "mobx";

import { IAlert } from "../types";
import RouterService from "../domain/Routing/utils/router-service";

class AlertStore {
  @observable
  public alerts: IAlert[] = [];

  constructor() {
    makeObservable(this);
    this.removeAlertsOnRouteChange = this.removeAlertsOnRouteChange.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.replace = this.replace.bind(this);
    this.removeAlertsOnRouteChange();
  }

  @action
  public removeAlertsOnRouteChange() {
    if (typeof window === "undefined") {
      return;
    }

    RouterService.onRouteChange(() => this.replace([]));
  }

  @action
  public add(alert: IAlert): number {
    return this.alerts.push(alert);
  }

  @action
  public remove(start: number, deleteCount?: number): IAlert[] {
    return this.alerts.splice(start, deleteCount);
  }

  @action
  public replace(alerts: IAlert[]): void {
    this.alerts = [...alerts];
  }
}

export default new AlertStore();
export { AlertStore };
