import { getMarkerIoDestinationConfig } from "./markerIoConfig";

const loadMarkerIo = async () => {
  const MarkerSDK = (await import("@marker.io/browser")).default;
  const markerIoDestination = getMarkerIoDestinationConfig();

  await MarkerSDK.loadWidget({
    project: markerIoDestination,
  });
};

export const markerIoManager = {
  loadMarkerIo,
};
