import { IPricingResponse } from "./types";

export const calculateTotalRecordAmount = (pricingResponse: IPricingResponse): number => {
  const recordCheck = pricingResponse?.recordCheck;
  return (
    (recordCheck?.displayRecords || 0) +
    (recordCheck?.externalReports?.us?.displayRecords || 0) -
    (recordCheck?.recordsPerCountry?.us || 0)
  );
};
