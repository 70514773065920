import Router, { SingletonRouter } from "next/router";
import { linkUtil } from "utils/i18n/link-util";

class RouterService {
  public router: SingletonRouter;

  constructor() {
    if (typeof window === "undefined") {
      return;
    }
    this.router = Router;
  }

  public routeTo(route) {
    this.router.push(linkUtil.i18nLink(route));
  }

  public routeWithQuery(route, query = {}) {
    this.router.push({ pathname: linkUtil.i18nLink(route), query });
  }

  public replace(route) {
    this.router.replace(linkUtil.i18nLink(route));
  }

  public hasQuerySearch() {
    if (typeof window === "undefined") {
      return false;
    }
    return window?.location?.search?.length > 0;
  }

  public getQueryObject(): any {
    if (typeof window === "undefined") {
      return {};
    }
    const queryString = window?.location?.search;
    const urlParams = new URLSearchParams(queryString);
    const paramsQuery: any = {};
    for (const entry of urlParams.entries()) {
      paramsQuery[entry[0]] = entry[1];
    }
    return paramsQuery;
  }

  public onRouteChange(cb: (url?) => void) {
    if (!this?.router?.events) {
      return;
    }
    this.router.events.on("routeChangeComplete", (url) => {
      cb(url);
    });
  }
}

export default new RouterService();
export { Router };
