import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useRef } from "react";
import { getSiteUrl } from "utils/config/site-url.helper";

export const TrackingContext = createContext(undefined);

export const TrackingCtxProvider = (props) => {
  const { children } = props;
  const { asPath } = useRouter();

  const asPathRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    asPathRef.current = `${getSiteUrl()}${asPath}`;
  }, [asPath]);

  return <TrackingContext.Provider value={asPathRef.current}>{children}</TrackingContext.Provider>;
};

const usePreviousURL = () => useContext(TrackingContext);
export default usePreviousURL;
