import React from "react";
import { ReactSVG } from "react-svg";
import { styles } from "./Icon.style";

export interface IconProps {
  icon: string;
  className?: string;
  desc?: string;
  title?: string;
}

export const Icon: React.FC<IconProps> = React.memo(
  React.forwardRef<HTMLImageElement, IconProps>((props, ref) => {
    const { icon, className, desc, title } = props;
    const svgRenderFallback = (src: string) => (
      <img className={className} alt={desc} title={title} src={src} role="presentation" />
    );
    return (
      <div css={styles.root()} ref={ref}>
        <ReactSVG
          css={styles.reactSvgIcon()}
          className={className}
          src={icon}
          desc={desc}
          title={title}
          fallback={svgRenderFallback}
          beforeInjection={(svg) => {
            svg.setAttribute("role", "presentation");
          }}
        />
      </div>
    );
  })
);

Icon.displayName = "Icon";
