const isBrowser = typeof window !== "undefined" && typeof window.document !== "undefined";

const scrollToElement = (id: string, verticalAlignment?: "start" | "center" | "end" | "nearest"): void => {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: verticalAlignment || "start" });
  }
};

export const browserUtil = {
  isBrowser,
  scrollToElement,
};
