import { Locales } from "./country";
import { languageConfig } from "./language-config";
import { i18n } from "next-i18next";

export class LanguageResolver {
  public static getCurrentLanguageShort() {
    return languageConfig.mapLocaleToLanguageShort(LanguageResolver.getCurrentLocale());
  }

  public static getCurrentLocale() {
    if (i18n) {
      return i18n?.language || Locales.USA;
    } else {
      return Locales.USA;
    }
  }
}
