import { useMemo } from "react";
import { languageConfig } from "i18n/language-config";

export const useAvailableLanguage = () => {
  const languages = languageConfig.getLocales();

  return useMemo(() => {
    return languages.map(languageConfig.mapLocaleToLanguageShort);
  }, [languages]);
};
