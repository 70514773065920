import { content, dimension, mediaQuery, zIndex } from "@carfax/web-ui/core";
import { css } from "@emotion/react";

export const styles = {
  divAlerts: () => {
    return css({
      left: 0,
      width: "100%",
      margin: "20px 0",
      padding: `${content.padding + 20}px ${content.padding}px 0px ${content.padding}px`,
      zIndex: zIndex.maxZIndex,
      position: "static",
      top: `${dimension.header.height}px`,
    });
  },
  divBoxedLayout: () =>
    css({
      maxWidth: content.width,
      margin: `0 auto`,
      display: "flex",
      flexDirection: "column",
      padding: null,
      [mediaQuery.desktopOrLarger]: {
        padding: `0 ${content.padding}px`,
      },
    }),
};
